import {
    RouteLocationNormalized, RouteRecordRaw, createRouter, createWebHistory,
} from 'vue-router';
import { watch } from 'vue';
import { ExtendedPageMeta } from '~/interfaces/app';
import { useOrganizationStore } from '~/store/organization';
import { useAuthenticationStore } from '~/store/authentication';

const routes: RouteRecordRaw[] = [
    {
        path: '/order',
        component: () => import('~/layouts/Authorized.vue'),
        props: true,
        children: [
            {
                path: ':orderId',
                name: 'order',
                component: () => import('~/views/Order.vue'),
                props: true,
                meta: {
                    requiresAuth: false,
                } as ExtendedPageMeta,
                children: [
                ],
            },
        ],
    },
    {
        path: '',
        component: () => import('~/layouts/Authorized.vue'),
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('~/views/Home.vue'),
                meta: {
                    requiresAuth: false,
                } as ExtendedPageMeta,
            },
        ],
    },
    {
        path: '/verify/:token',
        redirect: { name: 'home' },
    },
    {
        path: '/',
        component: () => import('~/layouts/Authorized.vue'),
        children: [
            {
                path: ':orgId',
                name: 'home_org',
                component: () => import('~/views/Home.vue'),
                props: true,
                meta: {
                    requiresAuth: false,
                } as ExtendedPageMeta,
            },
            {
                path: 'exchange',
                name: 'exchange',
                component: () => import('~/views/Home.vue'),
                props: true,
                meta: {
                    requiresAuth: false,
                } as ExtendedPageMeta,
            },
            {
                path: 'prefill',
                name: 'prefill',
                redirect: (to) => {
                    localStorage.setItem('QUERY_PARAMS', JSON.stringify(to.query));
                    return { name: 'home' };
                    // return { name: 'home', params: { orgId: to.params?.orgId } };
                },
            },
            {
                path: 'login',
                redirect: { name: 'home' },
            },
            {
                path: ':orgId/paymentReminder',
                name: 'paymentReminder',
                component: () => import('~/views/PaymentReminder.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: ':orgId/orders',
                name: 'orders',
                component: () => import('~/views/Orders.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: ':orgId/accounts',
                name: 'accounts',
                component: () => import('~/views/Accounts.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: ':orgId/contracts',
                name: 'contracts',
                component: () => import('~/views/Contracts.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: ':orgId/contract/:contractId',
                name: 'contractDetail',
                component: () => import('~/views/Contract.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: ':orgId/contract/:contractId',
                name: 'contractDetail',
                component: () => import('~/views/Contract.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: ':orgId/account/:accountId',
                name: 'account',
                component: () => import('~/views/Account.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: ':orgId/deposits',
                name: 'deposits',
                component: () => import('~/views/Deposits.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: ':orgId/adm',
                name: 'adms',
                component: () => import('~/views/Adms.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: ':orgId/settings',
                name: 'organization_settings',
                component: () => import('~/views/Settings.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: ':orgId/receivablesManagement',
                name: 'receivablesManagement',
                component: () => import('~/views/ReceivablesManagement.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: ':orgId/installmentCalculator',
                name: 'installmentCalculator',
                component: () => import('~/views/InstallmentCalculator.vue'),
                props: true,
            },
            {
                path: ':orgId/carFinancingCalculator',
                name: 'carFinancingCalculator',
                component: () => import('~/views/carFinancingCalculator.vue'),
                props: true,
            },
            {
                path: ':orgId/drafts',
                name: 'drafts',
                component: () => import('~/views/Drafts.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: ':orgId/reporting',
                name: 'reporting',
                component: () => import('~/views/Report.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: '/clients',
                name: 'clients',
                component: () => import('~/views/Clients.vue'),
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: 'match',
                name: 'match',
                component: () => import('~/views/Match.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: ':orgId/bundledPayments',
                name: 'bundledPayments',
                component: () => import('~/components/order/BundledPayments.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                } as ExtendedPageMeta,
            },
            {
                path: 'organization/adm/:orgId',
                name: 'adm',
                component: () => import('~/views/Adms.vue'),
                props: true,
                meta: {
                    requiresAuth: false,
                } as ExtendedPageMeta,
            },
        ],
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('~/layouts/Authorized.vue'),
        children: [
            {
                path: 'settings',
                name: 'userSettings',
                component: () => import('~/views/UserSettings.vue'),
            },
            {
                path: 'authorise',
                name: 'authorise',
                component: () => import('~/views/Authorise.vue'),
            },
        ],
        meta: {
            requiresAuth: true,
        } as ExtendedPageMeta,
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('~/components/authentication/Signup.vue'),
    },
    {
        path: '/auth',
        name: 'auth',
        component: () => import('~/layouts/Unauthorized.vue'),
    },
    {
        path: '/401',
        name: '401',
        component: () => import('~/layouts/Unauthorized.vue'),
        children: [
            {
                path: '',
                name: '401',
                component: () => import('~/components/feedback/401.vue'),
            },
        ],
    },
    {
        path: '/password',
        name: 'password',
        component: () => import('~/layouts/Unauthorized.vue'),
        children: [
            {
                path: 'request',
                name: 'request',
                component: () => import('~/components/authentication/Password.vue'),
                props: true,
            },
            {
                path: 'reset',
                name: 'reset',
                component: () => import('~/layouts/Unauthorized.vue'),
                props: true,
                children: [
                    {
                        path: ':token',
                        name: 'token',
                        component: () => import('~/components/authentication/PasswordReset.vue'),
                        props: true,
                        meta: {
                            requiresAuth: false,
                        } as ExtendedPageMeta,
                    },
                ],
            },
        ],
        meta: {
            requiresAuth: false,
        } as ExtendedPageMeta,
    },
    {
        path: '/yourorder/:orderId',
        name: 'yourorder',
        props: true,
        component: () => import('~/views/YourOrder.vue'),
    },
    {
        path: '/youraccount/:accountId',
        name: 'youraccount',
        props: true,
        component: () => import('~/views/YourAccount.vue'),
    },
    {
        path: '/auth/verify/:token',
        name: 'auth',
        component: () => import('~/components/authentication/Auth.vue'),
    },
    {
        path: '/carFinancingCalculator',
        name: 'soloCarFinancingCalculator',
        component: () => import('~/views/carFinancingCalculator.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL || process.env.BASE_URL),
    routes,
});

router.beforeEach((to: RouteLocationNormalized, _from: RouteLocationNormalized, next) => {
    const organizationStore = useOrganizationStore();
    const { orgId } = to.params;
    if (orgId) organizationStore.$patch({ activeOrgId: `${orgId}` });
    return next();
});

router.beforeEach((to, from, next) => {
    const authenticationStore = useAuthenticationStore();
    let auth = false;
    watch(() => authenticationStore.isAuthenticated, (newVal) => {
        auth = newVal;
    });
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (auth) {
            next({ name: 'home' });
        }
    }
    return next();
});
export default router;
